import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
import {
  Uploader,
  Button,
  Icon,
  Tab,
  Stepper,
  Area,
  Tabs,
  AddressEdit,
  Collapse,
  CollapseItem,
  Picker,
  Swipe,
  SwipeItem,
  Dialog,
  RadioGroup,
  Radio,
  Search,
  Form,
  Popup,
  Field,
  CellGroup,
  Toast,
  Steps,
  Step,
  Calendar,
  Cell,
} from "vant";

Vue.use(Uploader)
  .use(Button)
  .use(Icon)
  .use(Tab)
  .use(Stepper)
  .use(Area)
  .use(AddressEdit)
  .use(Tabs)
  .use(Collapse)
  .use(Picker)
  .use(RadioGroup)
  .use(Radio)
  .use(CollapseItem)
  .use(Swipe)
  .use(SwipeItem)
  .use(Dialog)
  .use(Search)
  .use(Form)
  .use(Field)
  .use(Popup)
  .use(CellGroup)
  .use(Toast)
  .use(Steps)
  .use(Step)
  .use(Calendar)
  .use(Cell);

Vue.config.productionTip = false;

import service from "./utils/request";
import headCom from "@/components/headCom";
Vue.component("headCom", headCom);
Vue.prototype.$axios = service;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
