<template>
  <div>
    <headCom></headCom>
    <div class="content">
      <div>
        <p class="size-15 mb-16 bold">产品信息</p>
        <van-form @submit="onSubmit">
          <van-field readonly clickable required :rules="[{ required: true }]" v-model="infos.souceValue" label="来源"
            placeholder="请选择来源" @click="soucePiceker = true" />
          <van-popup v-model="soucePiceker" position="bottom">
            <van-picker show-toolbar value-key="enterpriseName" :columns="souceColumns" @confirm="onConfirm"
              @cancel="soucePiceker = false" />
          </van-popup>

          <div v-for="(item, index) in cteiList" :key="index" class="size-14 flex mt-10 pr-16">
            <van-field :rules="[{ required: true }]" required @change="handleBlur(index)" v-model="item.value"
              show-word-limit maxlength="15" label="CTEI码" placeholder="请输入15位CTEI码" />
            <van-icon name="scan" class="mr-16" size="16" @click="handleScan(index)" />
          </div>
          <div class="blue size-12 flex_r mt-4" @click="showCTEI = true">
            点击了解CTEI码>>
          </div>
          <van-popup round v-model="showCTEI">
            <div class="fill-info">
              <div class="text-center mb-20">
                <div class="size-16 mb-14">什么是CTEI码？</div>
                <img class="w100" src="@/assets/home/ctei.png" alt="" />
              </div>
              <div class="submitNext size-16" @click="showCTEI = false">
                我知道了
              </div>
            </div>
          </van-popup>
          <van-field v-model="infos.clientRequire" label="故障描述" placeholder="请填写故障描述" />
          <div class="blue center mt-12 size-14">
            如果您有两把锁，请填写2次预约安装
          </div>
          <div class="bg mt-20"></div>
          <p class="size-15 mb-16 bold mt-16">客户信息</p>

          <van-field :rules="[{ required: true }]" v-model="infos.clientName" label="姓名" required placeholder="请输入姓名" />
          <van-field v-model="infos.clientPhone" required type="tel" label="手机号" :rules="[
            { required: true },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]" placeholder="请输入手机号" />

          <van-field :rules="[{ required: true }]" readonly required clickable name="area" :value="infos.area"
            label="地区选择" placeholder="点击选择省市区" @click="showArea = true" />
          <van-popup v-model="showArea" position="bottom">
            <van-area :area-list="areaList" @confirm="onAreaConfirm" @cancel="showArea = false" />
          </van-popup>
          <van-field :rules="[{ required: true }]" v-model="infos.clientAddress" required label="详细地址"
            placeholder="请输入详细地址" />
          <van-field v-model="infos.broadbandAccount" label="宽带账号" placeholder="请输入宽带账号" />
          <van-field v-model.trim="infos.telecomPhone" label="电信手机号" :rules="[{ validator, message: '手机号码格式错误' }]"
            placeholder="请输入电信手机号" />
          <div class="bg mt-20"></div>
          <p class="size-15 mb-16 bold mt-16">故障照片和视频</p>
          <div>
            <div class="size-14 mtb-12">
              上传故障图片<span class="red">（1至3张）</span>
            </div>
            <van-uploader v-model="fileList1" :after-read="afterRead1" multiple :max-count="3" />
          </div>
          <div>
            <div class="size-14 mtb-12">
              上传故障视频<span class="red">（0至2个）</span>
            </div>
            <van-uploader accept="video/*" v-model="fileList2" :after-read="afterRead2" multiple :max-count="2" />
          </div>
          <div class="mt-50">
            <div class="btn size-16">
              <van-button size="normal" native-type="button" round @click="handleLast">上一步</van-button>
              <van-button size="normal" round type="info" native-type="submit">提交</van-button>
            </div>
          </div>
        </van-form>
      </div>
    </div>

    <van-popup v-model="showScan" class="w100 h100">
      <Scan @handleScan="getScan" v-if="showScan"></Scan>
    </van-popup>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import Scan from "../scan/index.vue";
import wx from 'weixin-js-sdk';
export default {
  data() {
    return {
      showCTEI: false,
      fileList1: [], //三张
      fileList2: [], //1张
      active: 1,
      souceColumns: [],
      areaList,
      soucePiceker: false,
      infos: {
        infoList: [],
        installType: "SMART_SWITCH",
        guideSizePic: [],
        guideTypePic: "",
      },
      cteiList: [{ value: "", enterpriseName: "" }],

      cteiCount: "",
      writeInfo: {
        handRadio: "1",
      },
      infoList: [
        { infoName: "门厚(mm):", infoValue: null, dictType: "door_ply" },
        {
          infoName: "导向片长度(mm)",
          infoValue: null,
          dictType: "guide_length",
        },
        {
          infoName: "导向片宽度(mm)：",
          infoValue: null,
          dictType: "guide_width",
        },
        { infoName: "导向片类型：", infoValue: null, dictType: "guide_type" },
        { infoName: "导向片孔位：", infoValue: null, dictType: "guide_hole" },
        { infoName: "是否有天地勾：", infoValue: null, dictType: "world_hook" },
      ],
      doorList: [],
      // 省份id
      provinceCode: null,
      // 城市id
      cityCode: null,
      // 区县id
      areaCode: null,
      // 来源id
      sourceEnterpriseId: null,
      // 扫一扫弹窗
      showScan: false,
      scanIndex: null,
      nums: 1,
      showArea: false,
      showWrite: false,
      // 品牌Id
      brandId: null,
    };
  },
  created() {
    this.infos.orderType = this.$route.query.orderTypeId;
    this.infos.installTimeTelecommunications = this.$route.query.date;

    if (Object.keys(this.$store.state.infos).length != 0) {
      this.cteiList = [];
      this.infos = this.$store.state.infos;
      this.doorList = this.infos.infoList;
      let list = this.infos.ctei.split(",");
      let names = this.infos.names.split(",");
      list.forEach((item) => {
        let obj = {};
        obj.value = item;
        this.cteiList.push(obj);
      });
      this.cteiList[0].enterpriseName = names[0];
    }
  },
  mounted() {
    // 来源数据
    this.getSourceEnter();
  },
  methods: {
    // 校验函数返回 true 表示校验通过，false 表示不通过
    validator(val) {
      if (!val) {
        return true;
      } else {
        return /^1[3456789]\d{9}$/.test(val);
      }
    },
    upload(file) {
      // 此时可以自行将文件上传至服务器
      const forms = new FormData();
      //这里的file表示给后台传的属性名字，这里看接口文档需要传的的属性
      forms.append("file", file.file); // 获取上传图片信息
      return this.$axios.post("/stage-api/dfs/customerUpload", forms, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    },
    // 每次上传最后一张
    afterRead1() {
      this.fileList1.forEach((item) => {
        if (item.status != "success") {
          this.$set(item, "status", "uploading");
          this.$set(item, "message", "上传中");
          this.upload(item)
            .then((res) => {
              this.$set(item, "img", res.url);
              this.$set(item, "status", "success");
            })
            .catch(() => {
              this.$set(item, "img", "");
              this.$set(item, "status", "failed");
            });
        }
      });
    },
    afterRead2() {
      this.fileList2.forEach((item) => {
        if (item.status != "success") {
          this.$set(item, "status", "uploading");
          this.$set(item, "message", "上传中");
          this.upload(item)
            .then((res) => {
              this.$set(item, "img", res.url);
              this.$set(item, "status", "success");
            })
            .catch(() => {
              this.$set(item, "img", "");
              this.$set(item, "status", "failed");
            });
        }
      });
    },
    async onSubmit() {
      let list = [];
      this.cteiList.forEach((e) => {
        list.push(e.value);
      });
      this.infos.ctei = list.toString();
      // this.infos.infoList = this.infoList;
      this.infos.number = this.cteiList.length;

      if (this.fileList1.length < 1) {
        this.$toast("故障图片至少上传一张");
        return;
      }

      let arr = this.fileList1.filter((item) => {
        return item.status == "success";
      });

      if (arr.length < this.fileList1.length) {
        this.$toast("故障图片正在上传中...");
        return;
      }
      this.infos.faultPic = this.fileList1.map((item) => {
        return item.img;
      });
      if (this.fileList2.length > 0) {
        let arr2 = this.fileList2.filter((item) => {
          return item.status == "success";
        });

        if (arr2.length < this.fileList2.length) {
          this.$toast("故障视频正在上传中...");
          return;
        }
        this.infos.faultVideo = this.fileList2.map((item) => {
          return item.img;
        });
      }
      this.$axios
        .post("/stage-api/order/customer/addCustomerOrder", this.infos)
        .then(() => {
          this.$router.push("/buySuccess");
        });
    },

    // 删除CTEI码
    handleDel(index) {
      this.cteiList.splice(index, 1);

      this.infos.ctei = this.cteiList.toString();
    },

    //扫描二维码

    handleScan(index) {
      this.scanIndex = index;
      let url = '';
      if (navigator.userAgent.indexOf('iPhone') !== -1) {
        // bug；IOS 获取签名传递的url必须是项目一打开的首页url，否则会签名无效，安卓可以传递当前页面
        url = location.origin + '/';
      } else {
        url = location.href
      }
      // 掉接口，获取所需要的配置参数
      this.$axios
        .get("/stage-api/system/wechat/getWxSign", {
          params: {
            url: encodeURIComponent(url)
          }
        })
        .then(res => {
          let data = res.data
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名，见附录1
            jsApiList: ["scanQRCode"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });

          wx.ready(() => {
            // 9.1.2 扫描二维码并返回结果
            wx.scanQRCode({
              needResult: 1,
              desc: "scanQRCode desc",
              success: (res) => {
                let code = ''
                if (res.resultStr.includes(',')) {
                  code = res.resultStr.split(',')[1]
                } else {
                  code = res.resultStr
                }
                this.getScan(code)
              }
            });
          });
          //初始化jsapi接口 状态
          wx.error(function (res) {
            alert("调用微信jsapi返回的状态:" + res.errMsg);
          });
        });
    },
    // 接受扫描的值
    async getScan(item) {
      let index = this.scanIndex;
      this.cteiList[index].value = item;
      await this.$axios
        .get("/stage-api/system/ctei/getInfoByCtei", {
          params: {
            ctei: this.cteiList[index].value,
          },
        })
        .then((res) => {
          if (res.data != null && res.code == 0) {
            this.cteiList[index].enterpriseName = res.data.enterpriseName;

            this.infos.brandId = res.data.brandId;
            this.infos.enterpriseId = res.data.enterpriseId;
            this.infos.modelId = res.data.modelId;
            this.infos.model = res.data.brandName + res.data.modelName;
            this.cteiList[index].value = res.data.ctei;
            if (index >= 1) {
              if (
                this.cteiList[0].enterpriseName ==
                this.cteiList[index].enterpriseName
              ) {
                this.cteiList[index].value = res.data.ctei;
              } else {
                this.cteiList[index].value = "";
                this.$toast.fail("不支持不同锁企的CTEI码!");
              }
            }
          } else {
            this.$toast.fail("未知的CTEI码!");
            // this.cteiList[index].value = "";
          }
        });
    },

    // 来源数据
    getSourceEnter() {
      this.$axios
        .get("/stage-api/enterprise/management/getEnterpriseList", {
          params: {
            type: 1,
          },
        })
        .then((res) => {
          this.souceColumns = JSON.parse(JSON.stringify(res.data));
        });
    },

    handleRest() {
      this.showWrite = false;
    },
    onConfirm(value) {
      this.infos.souceValue = value.enterpriseName;
      this.infos.sourceEnterpriseId = value.enterpriseId;
      this.soucePiceker = false;
    },

    onAreaConfirm(value) {
      this.infos.area = value
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
      this.infos.provinceCode = value[0].code;
      this.infos.cityCode = value[1].code;
      this.infos.areaCode = value[2].code;
    },

    handleWrite() {
      this.infoList.map((item) => {
        return (item.infoValue = "");
      });
      this.infoList[4].handRadio = "是";
      this.showWrite = true;
    },
    // 添加ctei
    hanndleAdd() {
      if (this.cteiList.length == 0) {
        this.infos.brandId = null;
        this.infos.brandId = null;
        this.infos.brandId = null;

        this.cteiList.push({
          value: "",
          enterpriseName: "",
        });
      } else {
        this.infos.number = this.cteiList.length;

        this.cteiList.push({
          value: "",
          enterpriseName: "",
        });
      }
    },
    handleBlur(index) {
      this.$axios
        .get("/stage-api/system/ctei/getInfoByCtei", {
          params: {
            ctei: this.cteiList[index].value,
          },
        })
        .then((res) => {
          if (res.data != null && res.code == 0) {
            this.cteiList[index].enterpriseName = res.data.enterpriseName;
            this.infos.brandId = res.data.brandId;
            this.infos.enterpriseId = res.data.enterpriseId;
            this.infos.modelId = res.data.modelId;
            this.infos.model = res.data.brandName + res.data.modelName;

            this.cteiList[index].value = res.data.ctei;

            if (index >= 1) {
              if (
                this.cteiList[0].enterpriseName ==
                this.cteiList[index].enterpriseName
              ) {
                this.cteiList[index].value = res.data.ctei;
              } else {
                this.cteiList[index].value = "";
                this.$toast.fail("不支持不同锁企的CTEI码!");
              }
            }
          } else {
            this.$toast.fail("未知的CTEI码!");
            this.cteiList[index].value = "";
          }
        });
    },
    handleEdit() {
      this.infoList = JSON.parse(JSON.stringify(this.doorList));
      this.showWrite = true;
    },
    // 返回上一级
    handleLast() {
      this.$router.push("/");
    },
  },
  components: { Scan },
};
</script>
<style lang="scss" scoped>
.submitNext {
  text-align: center;
  line-height: 44px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 1);
  // width: 303px;
  height: 44px;
  background: rgba(33.991499999999974, 126.00470549999994, 255, 1);
  border-radius: 25.5px;
}

.content {
  position: relative;
  padding: 16px;
  // width: 375px;
  background: rgba(255, 255, 255, 1);
  border-radius: 32px;
  margin-top: -70px;

  .btn {
    // position: absolute;
    bottom: 10px;
    width: 256px;
    height: 48px;
    line-height: 48px;
    display: flex;
    margin: 0 auto;
    margin-top: 10px;
    justify-content: flex-start;
  }

  .cteiBtn1 {
    width: 60px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #1989fa;
    color: #fff;
  }

  .cteiBtn2 {
    width: 60px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #ffffff;
    color: #1989fa;
  }
}

.van-button {
  width: 152px !important;
}

.bg {
  width: 100%;
  height: 10px;
  background: rgba(248, 248, 248, 1);
}

.line {
  height: 1px;
  background: #f6f6f6;
}

.write {
  display: flex;
  justify-content: center;
}

.fill-info {
  // text-align: center;
  padding: 20px;
  width: 315px;
  max-height: 702px;
  background: rgba(255, 255, 255, 1);
  border-radius: 24px;

  .info-img {
    width: 266px;
    height: 243px;
    border-radius: 67px;
    background: url("~@/assets/home/example.png");
    background-size: 100%;
  }

  .btn {
    width: 256px;
    height: 48px;
    line-height: 48px;
    display: flex;
    margin: 0 auto;
    margin-top: 17px;
    justify-content: flex-start;

    .btn1 {
      text-align: center;
      width: 128px;
      height: 48px;
      color: #227eff;
      background: rgba(241, 245, 248, 1);
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }

    .btn2 {
      text-align: center;
      width: 128px;
      height: 48px;
      background: rgba(34, 125.99999999999997, 255, 1);
      color: #ffffff;

      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }
}
</style>
