<template>
  <div>
    <div
      class="bg-yellow yellow pd-20 size-14"
      style="background: rgba(255, 250, 223, 1)"
    >
      为了完成智能锁的安排工作，需要完成预约安装信息采集，以便我司安排人员审核（通常1-3个工作日）并作安排
    </div>
    <div class="success mt-90">
      <div class="success-img"></div>
      <div class="size-18">恭喜您下单成功</div>
    </div>
    <div class="mt-80 text-center">
      <div class="size-14">请您保存以下电话</div>
      <div class="size-14 mt-5">关于锁具有任何问题第一时间联系客服</div>
      <div class="size-14 mt-10">
        <van-icon name="phone-o" class="mr-5" />400-928-0505
      </div>
      <div class="size-14 mt-10">
        <van-icon name="wechat" class="mr-5" /> 18551736595（微信）
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {},
  created() {
    // setTimeout(() => {
    //   this.$router.push("./");
    // }, 10000);
  },
};
</script>
<style lang="scss" scoped>
.success {
  text-align: center;
  position: relative;
  .success-img {
    margin: 0 auto;
    margin-right: 80px;
    width: 152px;
    height: 114px;
    background-size: 100%;
    background: url("../../assets/home/success.svg");
  }
}
</style>
