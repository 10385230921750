import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home/index.vue";
import writeInfo from "../views/writeInfo/index.vue";
import weixiu from "../views/writeInfo/index1.vue";
import buySuccess from "../views/buySuccess/index.vue";
import confirmOrder from "../views/confirmOrder/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "下单",
    },
  },
  {
    path: "/writeInfo",
    name: "writeInfo",
    component: writeInfo,
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "订单信息",
    },
  },
  {
    path: "/weixiu",
    name: "weixiu",
    component: weixiu,
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "订单信息",
    },
  },
  {
    path: "/confirmOrder",
    name: "confirmOrder",
    component: confirmOrder,
    meta: {
      isHide: false, //顶部是否返回，默认false
      title: "确定信息",
    },
  },
  {
    path: "/buySuccess",
    name: "buySuccess",
    component: buySuccess,
    meta: {
      isHide: true, //顶部是否返回，默认false
      title: "",
    },
  },
];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
