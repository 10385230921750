import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    infos: {},
    cteiList: [],
  },
  getters: {},
  mutations: {
    setInfos(state, data) {
      state.infos = data;
    },

    setcteiList(state, data) {
      state.cteiList = data;
    },
  },
  actions: {},
  modules: {},
  plugins: [persistedState()],
});
