<template>
  <div>
    <headCom></headCom>
    <div class="content">
      <van-steps :active="active">
        <van-step>下单</van-step>
        <van-step>订单信息</van-step>
        <van-step>确认订单</van-step>
      </van-steps>
      <div class="mt-30">
        <p class="size-16 mb-24">产品信息</p>
        <van-form @submit="onSubmit">
          <van-field
            readonly
            clickable
            required
            :rules="[{ required: true, message: '请选择来源' }]"
            v-model="infos.souceValue"
            label="来源"
            placeholder="请选择来源"
            @click="soucePiceker = true"
          />
          <van-popup v-model="soucePiceker" position="bottom">
            <van-picker
              show-toolbar
              value-key="enterpriseName"
              :columns="souceColumns"
              @confirm="onConfirm"
              @cancel="soucePiceker = false"
            />
          </van-popup>
          <div class="flex mt-20 mb-20 pl-16 pr-16">
            <div>CTEI码</div>
            <div>
              <van-icon size="16" name="plus" @click="hanndleAdd" />
            </div>
          </div>

          <div
            v-for="(item, index) in cteiList"
            :key="index"
            class="size-14 flex mt-10 pr-16"
          >
            <van-field
              :rules="[{ required: true }]"
              @blur="handleBlur(index)"
              v-model="item.value"
              label="CTEI"
              show-word-limit
              maxlength="15"
              placeholder="请输入15位CTEI码"
            />
            <van-icon
              name="scan"
              class="mr-16"
              size="16"
              @click="handleScan(index)"
            />
            <van-icon
              class="text-aligin:right"
              name="close"
              @click="handleDel(index)"
            />
          </div>
          <van-field
            v-model="infos.clientRequire"
            label="特殊需求"
            placeholder="请填写特殊需求"
          />
          <div class="bg mt-20"></div>
          <p class="size-16 mb-16 mt-16">客户信息</p>

          <van-field
            :rules="[{ required: true }]"
            v-model="infos.clientName"
            label="姓名"
            required
            placeholder="请输入姓名"
          />
          <van-field
            v-model="infos.clientPhone"
            required
            type="tel"
            label="手机号"
            :rules="[
              { required: true },
              { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
            ]"
            placeholder="请输入手机号"
          />

          <van-field
            readonly
            clickable
            required
            name="area"
            :value="infos.area"
            label="地区选择"
            placeholder="点击选择省市区"
            @click="showArea = true"
          />
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :area-list="areaList"
              @confirm="onAreaConfirm"
              @cancel="showArea = false"
            />
          </van-popup>
          <van-field
            :rules="[{ required: true }]"
            v-model="infos.clientAddress"
            required
            label="详细地址"
            placeholder="请输入详细地址"
          />

          <div class="mt-24 write" v-if="doorList.length == 0">
            录入门信息有助于师傅更精准预判，<span
              class="blue"
              @click="handleWrite"
              >去填写>></span
            >
          </div>
          <div v-else>
            <div class="bg"></div>
            <div class="pl-16 pr-16 mt-16">
              <div class="flex">
                <div class="size-16">测量门信息</div>

                <img
                  @click="handleEdit"
                  src="../../assets/home/edit.svg"
                  alt=""
                />
              </div>
              <div class="line mt-16 mb-16"></div>
              <div class="flex size-14">
                <div class="">门厚(mm)</div>

                <div class="gray-3">{{ doorList[0].infoValue }}</div>
              </div>
              <div class="line mt-16 mb-16"></div>
              <div class="flex size-14">
                <div class="">导向片长度(mm)</div>

                <div class="gray-3">{{ doorList[1].infoValue }}</div>
              </div>
              <div class="line mt-16 mb-16"></div>
              <div class="flex size-14">
                <div class="">导向片宽度(mm)</div>

                <div class="gray-3">{{ doorList[2].infoValue }}</div>
              </div>
              <div class="line mt-16 mb-16"></div>
              <div class="flex size-14">
                <div class="">门缝距离(mm)</div>

                <div class="gray-3">{{ doorList[3].infoValue }}</div>
              </div>
              <div class="line mt-16 mb-16"></div>
              <div class="flex size-14">
                <div class="">是否有天地勾</div>

                <div class="gray-3" v-if="doorList[4].infoValue == '是'">
                  是
                </div>
                <div class="gray-3" v-else>否</div>
              </div>
              <div class="line mt-16 mb-16"></div>
            </div>
          </div>

          <div class="mt-50">
            <div class="btn size-16">
              <van-button
                size="normal"
                round
                native-type="button"
                @click="handleLast"
                >上一步</van-button
              >
              <van-button size="normal" round type="info" native-type="submit"
                >确认下单</van-button
              >
            </div>
          </div>
        </van-form>
      </div>
    </div>

    <van-popup round v-model="showWrite">
      <div class="fill-info">
        <div class="text-center">
          <div class="size-14">测量门信息</div>
          <div class="info-img mt-15"></div>
        </div>

        <div class="mt-9 mb-30 size-11 gray-3">
          <p>天地勾判断方法：</p>
          <p>1、用手摸门顶边缘是否锁孔</p>
          <p>2、在门锁弹出状态时，门顶边缘是否有锁舌弹出</p>
        </div>
        <div>
          <van-cell-group>
            <van-field
              label-width="120px"
              v-model="infoList[0].infoValue"
              label="门厚(mm):"
              placeholder="请输入门厚"
            />
            <van-field
              label-width="120px"
              v-model="infoList[1].infoValue"
              label="导向片长度(mm)："
              placeholder="请输入门厚"
            />
            <van-field
              label-width="120px"
              v-model="infoList[2].infoValue"
              label="导向片宽度(mm)："
              placeholder="请输入门厚"
            />
            <van-field
              label-width="120px"
              v-model="infoList[3].infoValue"
              label="门缝距离(mm)："
              placeholder="请输入门厚"
            />
            <van-field
              name="radio"
              label-width="120px"
              label="是否含有天地勾："
            >
              <template #input>
                <van-radio-group
                  v-model="infoList[4].infoValue"
                  direction="horizontal"
                >
                  <van-radio name="是">是</van-radio>
                  <van-radio name="否">否</van-radio>
                </van-radio-group>
              </template>
            </van-field>

            <div class="btn size-16">
              <div class="btn1" @click="handleRest">取消</div>
              <div class="btn2" @click="handleSubmit">提交</div>
            </div>
          </van-cell-group>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showScan" class="w100 h100">
      <Scan @handleScan="getScan" v-if="showScan"></Scan>
    </van-popup>
  </div>
</template>

<script>
import Scan from "../scan/index.vue";

import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      active: 2,
      souceColumns: [],
      areaList,
      soucePiceker: false,
      infos: {
        infoList: [],
        installType: "SMART_SWITCH",
      },
      cteiList: [{ value: "", enterpriseName: "" }],

      writeInfo: {
        handRadio: "1",
      },
      doorList: [],
      infoList: [
        { infoName: "门厚(mm):", infoValue: null, infoType: "1" },
        { infoName: "导向片长度(mm)", infoValue: null, infoType: "1" },
        { infoName: "导向片宽度(mm)：", infoValue: null, infoType: "1" },
        { infoName: "门缝距离(mm)：", infoValue: null, infoType: "1" },
        { infoName: "是否有天地勾：", infoValue: null, infoType: "是" },
      ],
      // 省份id
      provinceCode: null,
      // 城市id
      cityCode: null,
      // 区县id
      areaCode: null,
      // 来源id
      sourceEnterpriseId: null,
      // 扫一扫弹窗
      showScan: false,
      scanIndex: null,
      cteiInfo: null,
      nums: 1,
      showArea: false,
      showWrite: false,
      // 品牌Id
      brandId: null,
    };
  },
  async created() {
    this.cteiList = this.$route.query.cteiList;

    this.infos = this.$route.query.infos;
    this.doorList = this.infos.infoList;
  },
  mounted() {
    setTimeout(() => {
      this.getSourceEnter();
    }, 500);
  },
  methods: {
    onSubmit() {
      let list = [];
      this.cteiList.forEach((e) => {
        list.push(e.value);
      });
      this.infos.ctei = list.toString();
      this.infos.infoList = this.doorList;
      this.infos.number = this.cteiList.length;
      if (this.infos.number != 0 && this.cteiList.every((e) => e.value != "")) {
        let names = this.cteiList.map((item) => item.value);
        let nameSet = new Set(names);
        if (nameSet.size == names.length) {
          this.$axios
            .post("/stage-api/order/customer/addCustomerOrder", this.infos)
            .then(() => {
              this.$toast.success("下单成功");
              this.$router.push("/buySuccess");
            });
        } else {
          this.$toast.fail("CTEI码有重复值,请重新填写!");
        }
      } else {
        this.$toast.fail("CTEI码不能为空,请填写");
      }
    },
    // 删除CTEI码
    handleDel(index) {
      this.cteiList.splice(index, 1);

      this.infos.ctei = this.cteiList.toString();
    },
    // 来源数据
    getSourceEnter() {
      this.$axios
        .get("/stage-api/enterprise/management/getEnterpriseList", {
          params: {
            type: 1,
          },
        })
        .then((res) => {
          this.souceColumns = JSON.parse(JSON.stringify(res.data));
        });
    },
    handleSubmit() {
      const infoLists = this.infoList;
      if (this.infoList.every((e) => e.infoValue != "")) {
        this.doorList = JSON.parse(JSON.stringify(infoLists));
        this.showWrite = false;
      } else {
        this.$toast.fail("请填写所有信息!");
      }
    },
    handleRest() {
      this.showWrite = false;
    },
    onConfirm(value) {
      this.infos.souceValue = value.enterpriseName;
      this.infos.sourceEnterpriseId = value.enterpriseId;

      this.soucePiceker = false;
    },

    onAreaConfirm(value) {
      this.infos.area = value
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
      this.infos.provinceCode = value[0].code;
      this.infos.cityCode = value[1].code;
      this.infos.areaCode = value[2].code;
    },
    //扫描二维码
    handleScan(index) {
      this.showScan = true;
      this.scanIndex = index;
    },
    // 接受扫描的值
    async getScan(item, flag) {
      let index = this.scanIndex;
      this.showScan = flag;
      this.cteiList[index].value = item;
      await this.$axios
        .get("/stage-api/system/ctei/getInfoByCtei", {
          params: {
            ctei: this.cteiList[index].value,
          },
        })
        .then((res) => {
          if (res.data != null && res.code == 0) {
            this.cteiList[index].enterpriseName = res.data.enterpriseName;
            this.infos.brandId = res.data.brandId;
            this.infos.enterpriseId = res.data.enterpriseId;
            this.infos.modelId = res.data.modelId;
            this.infos.model = res.data.brandName + res.data.modelName;

            this.cteiList[index].value = res.data.ctei;

            if (index >= 1) {
              if (
                this.cteiList[0].enterpriseName ==
                this.cteiList[index].enterpriseName
              ) {
                this.cteiList[index].value = res.data.ctei;
              } else {
                this.cteiList[index].value = "";
                this.$toast.fail("不支持不同锁企的CTEI码!");
              }
            }
          } else {
            this.$toast.fail("未知的CTEI码!");
            this.cteiList[index].value = "";
          }
        });
    },
    handleWrite() {
      this.infoList.map((item) => {
        return (item.infoValue = null);
      });
      this.infoList[4].handRadio = "是";
      this.showWrite = true;
    },
    // 添加ctei
    hanndleAdd() {
      if (this.cteiList.length == 0) {
        this.infos.brandId = null;
        this.infos.brandId = null;
        this.infos.brandId = null;
        this.cteiInfo = null;
        this.cteiList.push({
          value: "",
          enterpriseName: "",
        });
      } else {
        this.cteiInfo = null;
        this.cteiList.push({
          value: "",
          enterpriseName: "",
        });
      }
    },
    handleBlur(index) {
      if (this.cteiList.includes(this.cteiInfo) == false) {
        this.$axios
          .get("/stage-api/system/ctei/getInfoByCtei", {
            params: {
              ctei: this.cteiList[index].value,
            },
          })
          .then((res) => {
            if (res.data != null && res.code == 0) {
              this.cteiList[index].enterpriseName = res.data.enterpriseName;
              this.infos.brandId = res.data.brandId;
              this.infos.enterpriseId = res.data.enterpriseId;
              this.infos.modelId = res.data.modelId;
              this.infos.model = res.data.brandName + res.data.modelName;

              this.cteiList[index].value = res.data.ctei;

              if (index >= 1) {
                if (
                  this.cteiList[0].enterpriseName ==
                  this.cteiList[index].enterpriseName
                ) {
                  this.cteiList[index].value = res.data.ctei;
                } else {
                  this.cteiList[index].value = "";
                  this.$toast.fail("不支持不同锁企的CTEI码!");
                }
              }
            } else {
              this.$toast.fail("未知的CTEI码!");
              this.cteiList[index].value = "";
            }
          });
      } else {
        this.$toast.fail("CTEI码重复!");
        this.cteiList[index].value = "";
      }
    },

    handleEdit() {
      this.infoList = JSON.parse(JSON.stringify(this.doorList));

      this.showWrite = true;
    },
    // 返回上一级
    handleLast() {
      this.$router.push("/writeInfo");
    },
  },

  components: { Scan },
};
</script>
<style lang="scss" scoped>
.content {
  position: relative;
  padding: 16px;
  width: 375px;
  background: rgba(255, 255, 255, 1);
  border-radius: 32px;
  margin-top: -60px;
  .btn {
    // position: absolute;
    bottom: 10px;
    width: 256px;
    height: 48px;
    line-height: 48px;
    display: flex;
    margin: 0 auto;
    margin-top: 10px;
    justify-content: flex-start;
  }
  .cteiBtn1 {
    width: 60px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #1989fa;
    color: #fff;
  }
  .cteiBtn2 {
    width: 60px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #ffffff;
    color: #1989fa;
  }
}
.van-button {
  width: 152px !important;
}
.bg {
  width: 100%;
  height: 10px;
  background: rgba(248, 248, 248, 1);
}
.line {
  height: 1px;
  background: #f6f6f6;
}
.write {
  display: flex;
  justify-content: center;
}
.fill-info {
  // text-align: center;
  padding: 20px;
  width: 315px;
  height: 702px;
  background: rgba(255, 255, 255, 1);
  border-radius: 24px;
  .info-img {
    width: 266px;
    height: 243px;
    border-radius: 67px;
    background: url("~@/assets/home/example.png");
    background-size: 100%;
  }
  .btn {
    width: 256px;
    height: 48px;
    line-height: 48px;
    display: flex;
    margin: 0 auto;
    margin-top: 17px;
    justify-content: flex-start;

    .btn1 {
      text-align: center;
      width: 128px;
      height: 48px;
      color: #227eff;
      background: rgba(241, 245, 248, 1);
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }
    .btn2 {
      text-align: center;
      width: 128px;
      height: 48px;
      background: rgba(34, 125.99999999999997, 255, 1);
      color: #ffffff;

      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }
}
</style>
