import axios from "axios";
import store from "@/store";
import { Toast } from "vant";

// 是否显示重新登录
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: "",
  // 超时
  timeout: 30000,
});

// request拦截器
service.interceptors.request.use((config) => {
  // 设置 token
  config.headers["Authorization"] = store.state.token; // 让每个请求携带自定义token 请根据实际情况自行修改
  return config;
});

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    if (res.data.code === 0) {
      return Promise.resolve(res.data);
    } else {
      Toast(res.data.msg);
      return Promise.reject(res.data);
    }
  },
  (error) => {
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Toast.fail(message);
    return Promise.reject(error);
  }
);

export default service;
