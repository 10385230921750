<template>
  <div>
    <headCom></headCom>

    <div class="lock"></div>

    <div class="content">
      <!-- <van-steps :active="active" class="plr-40">
        <van-step>下单</van-step>
        <van-step>订单信息</van-step>
        <van-step>确认订单</van-step>
      </van-steps> -->
      <div class="mt-16 flex_l">
        <div class="select-img" v-for="(item, index) in imgContent" :key="index" @click="handleActive(index)"
          :class="{ active: activeIndex == index }">
          <img v-if="activeIndex == index" :src="item.src1" class="selectImg" alt="" />
          <img v-else :src="item.src" class="selectImg" alt="" />

          <div class="mt-12 size-14">{{ item.info1 }}</div>
          <div class="mt-4 size-12 info2">{{ item.info2 }}</div>
        </div>
      </div>
    </div>
    <div class="bg mt-16"></div>
    <div class="pd-16">
      <div class="size-16">期望上门服务时间</div>
      <div class="flex mt-16">
        <div class="size-16">{{ monthDate }}</div>
        <div>
          <van-icon size="16" name="calendar-o" @click="show = true" />
        </div>
      </div>
      <div class="flex mt-16">
        <div class="dateList" :class="{ active: dateIndex == index }" @click="handleDate(item, index)"
          v-for="(item, index) in dateList" :key="index">
          <div class="size-10">{{ item.week }}</div>
          <div class="size-16 mt-5">{{ item.day }}</div>
        </div>
      </div>
      <van-calendar v-model="show" @confirm="onConfirm" :show-confirm="false" />
    </div>
    <div class="center mb-16 mt-50">如有疑问可联系客服：400-928-0505</div>
    <div class="submitNext size-16" @click="handleNext">下一步</div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      active: 0,
      activeIndex: 0,
      dateIndex: 0,
      activeDate: null,
      date: "",
      show: false,
      orderTypeId: null,
      typeList: [],
      dateList: [],
      // 当前时间
      nowDate: "",
      monthDate: "",
      imgContent: [
        {
          src: require("../../assets/home/step01.svg"),
          src1: require("../../assets/home/step01-active.svg"),
          info1: "上门安装",
          info2: "安装服务",
        },
        {
          src: require("../../assets/home/step02.svg"),
          src1: require("../../assets/home/step02-active.svg"),
          info1: "上门维修",
          info2: "维修服务",
        },
      ],
    };
  },
  async created() {
    this.nowDate = moment().format("YYYY-MM-DD");
    this.getDate();
    const { rows } = await this.$axios.get(
      "/stage-api/basicdata/orderType/list",
      {
        params: {
          pageNum: 1,
          pageSize: 10,
          installTypeCode: "SMART_SWITCH",
        },
      }
    );
    this.typeList = rows.filter(
      (e) => e.typeName == "安装单" || e.typeName == "维修单"
    );
    this.orderTypeId = this.typeList[0].orderTypeId;
  },

  mounted() { },
  methods: {
    handleActive(index) {
      this.activeIndex = index;
      if (index == 0) {
        this.orderTypeId = this.typeList[0].orderTypeId;
      } else {
        this.orderTypeId = this.typeList[1].orderTypeId;
      }
    },
    // 获取日期
    async getDate() {
      await this.$axios
        .get("/stage-api/system/time-utils/weekTime", {
          params: {
            baseDate: this.date,
          },
        })
        .then((res) => {
          this.monthDate = res.data.month;
          this.dateList = res.data.days;
          this.dateIndex = this.dateList.findIndex(
            (e) => e.day == this.activeDate
          );
        });
    },
    async getDate1() {
      await this.$axios
        .get("/stage-api/system/time-utils/weekTime", {
          params: {
            baseDate: this.date,
          },
        })
        .then((res) => {
          this.monthDate = res.data.month;
          this.dateList = res.data.days;
        });
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    formatDate1(date) {
      return `${date.getDate()}`;
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);
      this.activeDate = this.formatDate1(date);
      this.getDate();
    },
    handleDate(item, index) {
      if (this.compareDate(item.date, this.nowDate)) {
        this.dateIndex = index;
        this.date = item.date;
        // this.getDate1();
      }
    },
    // 日期比较大小
    compareDate(d1, d2) {
      let reg = new RegExp("-", "g");
      return new Date(d1.replace(reg, "/")) >= new Date(d2.replace(reg, "/"));
    },
    handleNext() {
      // if (this.date == null || this.date == "") {
      //   this.$toast.fail("请选择日期");
      //   return;
      // }
      console.log(this.activeIndex);
      this.$router.push({
        path: this.activeIndex == 0 ? "/writeInfo" : "weixiu",
        query: {
          date: this.date,
          orderTypeId: this.orderTypeId,
        },
      });
      this.$store.commit("setInfos", {});
      this.$store.commit("setcteiList", []);
    },
  },

  components: {},
};
</script>
<style lang="scss" scoped>
.lock {
  margin-left: 23px;
  margin-top: -89px;
  width: 103px;
  height: 51px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(166, 166, 166, 1);
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.28);
  border-radius: 19px;
  background: url("~@/assets/home/lock.svg");
  background-size: cover;
}

.active {
  background: rgba(33, 125.99999999999997, 255, 1) !important;
  color: #fff !important;
}

.content {
  padding: 16px;
  width: 375px;
  background: rgba(255, 255, 255, 1);
  border-radius: 32px;
  margin-top: 15px;

  .select-img {
    text-align: center;
    padding-top: 16px;
    width: 100px;
    height: 125px;
    background: rgba(241, 245, 248, 1);
    border-radius: 8px;
    margin-right: 16px;

    .selectImg {
      width: 40px;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 4px rgba(33, 126, 255, 0.28);
      border-radius: 16px;
    }
  }
}

.dateList {
  padding-top: 6px;
  text-align: center;
  width: 42px;
  height: 66px;
  background: rgba(241, 245, 248, 1);
  border-radius: 4px;
}

.submitNext {
  text-align: center;
  line-height: 48px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 1);
  width: 303px;
  height: 48px;
  background: rgba(33.991499999999974, 126.00470549999994, 255, 1);
  border-radius: 25.5px;
}

.bg {
  height: 10px;
  background: rgba(248, 248, 248, 1);
}
</style>
