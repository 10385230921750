<template>
  <div class="nav">
     <div style="width: 30px">
        <van-icon
          v-if="!$route.meta.isHide"
          name="arrow-left"
          class="size-18 white"
          @click="handleLast"
        />
      </div>
    <div class="size-18 title">{{ $route.meta.title }}</div>
    <div></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {
    // 返回上一级
    handleLast() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.nav {
  padding-top: 20px;
  padding-left: 10px;
  height: 150px;
  background: url("~@/assets/home/bg.png");
  background-size: cover;
  display: flex;
  justify-content: space-between;
}
.title{
  margin-left: -30px;
  color: #fff;
}
</style>
